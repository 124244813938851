<template>
  <!-- Content -->
  <div>
    <b-spinner
      v-if="isLoading"
      class="center-x my-3"
      small
      variant="primary"
      type="grow"
    />
    <unshare-item-modal
      v-model="isUnshareModalVisible"
      model-type="job"
      @remove-item="deleteItem(item)"
      @modal-invisible="isUnshareModalVisible = false"
    />
    <b-card v-if="item && isLoading === false" no-body>
      <feather-icon
        v-if="canDelete"
        icon="Trash2Icon"
        size="20"
        class="text-primary share-icon mr-3"
        role="button"
        @click="isUnshareModalVisible = true"
      />
      <b-dropdown
        id="dropdown-dropleft"
        variant="link"
        right
        class="dropdown-user"
        no-caret
      >
        <!-- Edit job -->
        <template #button-content class="edit-button">
          <feather-icon
            v-if="canEdit"
            v-b-tooltip.hover.bottom
            icon="Edit2Icon"
            size="20"
            :title="$t('edit-dropdown.title')"
            class="text-primary edit-icon mr-2"
          />
        </template>
        <b-dropdown-text class="bg-light">
          <p class="user-name font-weight-bolder mb-0">
            {{ $t("edit-dropdown.title") }}
          </p>
        </b-dropdown-text>
        <b-dropdown-item v-b-modal.modal-edit-job>
          {{ $t("edit-dropdown.details") }}
        </b-dropdown-item>
        <!-- Add classifiers -->
        <b-dropdown-item
          v-if="canEdit"
          @click="isClassifiersModalVisible = true"
        >
          {{ $t("members.edit.classifiers.name") }}
        </b-dropdown-item>
        <b-dropdown-item
          v-if="canEdit"
          @click="isCustomFieldsModalVisible = true"
        >
          {{ $t("members.edit.custom-fields.label") }}
        </b-dropdown-item>
      </b-dropdown>

      <feather-icon
        v-b-tooltip.hover.bottom
        icon="Share2Icon"
        :title="$t('share.tooltip')"
        size="20"
        class="text-primary share-icon"
        @click="isShareModalVisible = true"
      />

      <b-card-body>
        <b-row class="my-2">
          <!-- RIGHT: Job Details -->
          <b-col md="7" class="ml-2 mr-1 mr-md-0">
            <!-- Job Name -->
            <h3 class="mb-2">
              {{ item.title }}
            </h3>
            <!-- Job Location -->
            <div v-if="item" class="d-flex mb-1">
              <b-icon-geo-alt class="mt-25" />
              <b-card-text class="float-right ml-2">
                <span
                  v-if="item.location && item.location.name"
                >{{ item.location.name }}, </span><span
                  v-if="item.location"
                >{{ item.location.locality }},
                  {{ item.location.region }}</span>
              </b-card-text>
            </div>
            <!-- Job Responsibilities -->
            <div v-if="item.responsibilities" class="d-flex mb-1">
              <b-icon-briefcase class="mt-25" />
              <b-card-text class="float-right ml-2">
                {{ item.responsibilities }}
              </b-card-text>
            </div>
            <!-- Job Vacants -->
            <div v-if="item.vacants" class="d-flex mb-1">
              <b-icon-people class="mt-25" />
              <b-card-text>
                <span
                  class="float-right ml-2"
                >{{ item.vacants }} {{ $t("jobs.detail.vacants") }}</span>
              </b-card-text>
            </div>
            <!-- Job Experience -->
            <div
              v-if="item.minYearsExp || item.maxYearsExp"
              class="d-flex mb-1"
            >
              <b-icon-clock-history class="mt-25" />
              <b-card-text
                v-if="item.minYearsExp && item.maxYearsExp"
                class="float-right ml-2"
              >
                {{ item.minYearsExp }} - {{ item.maxYearsExp }}
                {{ $t("jobs.detail.years") }}
              </b-card-text>
              <b-card-text
                v-else-if="item.minYearsExp && !item.maxYearsExp"
                class="float-right ml-2"
              >
                {{ $t("jobs.detail.minimum") }} {{ item.minYearsExp }}
                {{ $t("jobs.detail.years") }}
              </b-card-text>
              <b-card-text
                v-else-if="!item.minYearsExp && item.maxYearsExp"
                class="float-right ml-2"
              >
                {{ item.maxYearsExp }} {{ $t("jobs.detail.years") }}
              </b-card-text>
            </div>
            <!-- Job Salary -->
            <div
              v-if="item.minBruttoSalary || item.maxBruttoSalary"
              class="d-flex mb-1"
            >
              <b-icon-cash class="mt-25" />
              <b-card-text
                v-if="item.minBruttoSalary && item.maxBruttoSalary"
                class="float-right ml-2"
              >
                {{ $t("jobs.detail.salary") }}:
                {{
                  parseFloat(item.minBruttoSalary).toLocaleString(
                    currentLocale,
                    {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 3,
                    }
                  )
                }}
                -
                {{
                  parseFloat(item.maxBruttoSalary).toLocaleString(
                    currentLocale,
                    {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 3,
                    }
                  )
                }}
                {{ item.currency }}
              </b-card-text>
              <b-card-text
                v-else-if="item.minBruttoSalary && !item.maxBruttoSalary"
                class="float-right ml-2"
              >
                {{ $t("jobs.detail.salary") }}:
                {{
                  parseFloat(item.minBruttoSalary).toLocaleString(
                    currentLocale,
                    {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 3,
                    }
                  )
                }}
                {{ item.currency }}
              </b-card-text>
              <b-card-text
                v-else-if="!item.minBruttoSalary && item.maxBruttoSalary"
                class="float-right ml-2"
              >
                {{ $t("jobs.detail.salary") }}:
                {{
                  parseFloat(item.maxBruttoSalary).toLocaleString(
                    currentLocale,
                    {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 3,
                    }
                  )
                }}
                {{ item.currency }}
              </b-card-text>
            </div>
            <!-- Job Description -->
            <h5 class="mb-1 mt-3">
              {{ $t("jobs.detail.description") }}
            </h5>
            <div v-if="item.description" v-html="item.description" />

            <hr>
            <h5 class="mb-1 mt-50">
              {{ $t("jobs.detail.contact") }}
            </h5>
            <b-button
            v-if="showApplyButton"
              variant="primary"
              class="my-50"
              :target="item.joinURL?'_blank':''"
              :href="item.joinURL ? item.joinURL : ''"
              @click="item.joinURL ? '' : isContactModalVisible = true"
            >
              {{ $t("jobs.detail.apply") }}
            </b-button>
            <hr>
            <!--Custom fields-->
            <div v-for="(field, index) in item.custom" :key="index">
              <b-row
                v-if="
                  field.value ||
                    (typeof field.value !== 'string' && !field.value)
                "
                class="mb-1"
              >
                <b-col class="font-weight-bold">
                  {{ translate(field.customFieldName) }}:
                </b-col>
                <b-col>
                  {{ getValue(field.value) }}
                </b-col>
              </b-row>
            </div>

            <!-- Classifiers -->
            <template
              v-if="classifiers && classifiers.length > 0"
              v-key="classifiers"
              class="my-50"
            >
              <div v-for="(classifierType, name) in sortedClassifiersTypes" :key="classifierType.key">
                <div class="w-100">
                  {{ name }}:
                </div>
                <span v-for="(classifier, index) in classifierType" :key="index">
                  <b-badge class="mb-50 mr-50" :style="classifierStyle(classifier)">
                    {{ translate(classifier.name) }}
                  </b-badge>
                </span>
              </div>
            </template>

          </b-col>
        </b-row>
      </b-card-body>

      <!-- Add classifier modal -->
      <classifiers-modal
        v-model="isClassifiersModalVisible"
        :item-key="item.key"
        morph-type="jobs"
        model-type="job"
        :classifiers="classifiers"
        @profileUpdated="fetchClassifiers"
      />
      <!-- Add custom field modal -->
      <customfields-modal
        v-model="isCustomFieldsModalVisible"
        :item="item"
        morph-type="job"
        @profileUpdated="handleCustomFields"
      />
      <!-- Share job modal -->
      <share-modal
        v-model="isShareModalVisible"
        :can-share="false"
        :item-name="item.title"
        model-type="jobs"
        type="jobs"
        :model-key="item.key"
      />

      <!-- Edit job modal -->
      <b-modal
        id="modal-edit-job"
        :title="$t('projects.modal-edit-title')"
        cancel-variant="outline-secondary"
        :ok-title="$t('form.actions.save')"
        :cancel-title="$t('form-create-item.cancel')"
        centered
        size="lg"
        @ok="handleEditJob"
        @show="openEditModal"
      >
        <b-form v-if="Object.keys(itemInput).length > 0">
          <b-form-group>
            <label for="title">{{ $t("form-create-item.title") }}:</label>
            <b-form-input
              id="title"
              v-model="itemInput.title"
              type="text"
              required
            />
          </b-form-group>

          <b-row>
            <b-col cols="9">
              <b-form-group>
                <label
                  class="mt-2"
                  for="responsibilities"
                >{{ $t("form-create-item.responsibilities") }}:</label>
                <b-form-input
                  id="responsibilities"
                  v-model="itemInput.responsibilities"
                  required
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group>
                <label
                  class="mt-2"
                  for="vacants"
                >{{ $t("form-create-item.vacants") }}:</label>
                <b-form-input
                  id="vacants"
                  v-model="itemInput.vacants"
                  type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group>
                <label
                  class="mt-2"
                  for="min-exp"
                >{{ $t("form-create-item.min-years-exp") }}:</label>
                <b-form-input
                  id="min-exp"
                  v-model="itemInput.minYearsExp"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group>
                <label
                  class="mt-2"
                  for="max-exp"
                >{{ $t("form-create-item.max-years-exp") }}:</label>
                <b-form-input
                  id="max-exp"
                  v-model="itemInput.maxYearsExp"
                  type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group>
                <label
                  class="mt-2"
                  for="min-salary"
                >{{ $t("form-create-item.min-brutto-salary") }}:</label>
                <b-form-input
                  id="min-salary"
                  v-model="itemInput.minBruttoSalary"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group>
                <label
                  class="mt-2"
                  for="max-salary"
                >{{ $t("form-create-item.max-brutto-salary") }}:</label>
                <b-form-input
                  id="max-salary"
                  v-model="itemInput.maxBruttoSalary"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <!-- Currency -->
            <b-col cols="12">
              <b-form-group
                :label="$t('backoffice.subscriptions.form.currency')"
                label-for="currency"
              >
                <b-form-select
                  id="currency"
                  v-model="itemInput.currency"
                  :options="currencyCode"
                  :placeholder="$t('form.type-placeholder')"
                  label-type="text"
                  @keydown.enter.prevent="onEnter"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group>
            <label
              class="mt-2"
              for="name"
            >{{ $t("form-create-item.name") }}:</label>
            <b-form-input
              id="name"
              v-model="itemInput.location.name"
              required
              type="text"
            />
          </b-form-group>
          <b-row>
            <b-col cols="6">
              <b-form-group>
                <label
                  class="mt-2"
                  for="locality"
                >{{ $t("form-create-item.locality") }}:</label>
                <b-form-input
                  id="locality"
                  v-model="itemInput.location.locality"
                  required
                  type="text"
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group>
                <label
                  class="mt-2"
                  for="region"
                >{{ $t("form-create-item.region") }}:</label>
                <b-form-input
                  id="region"
                  v-model="itemInput.location.region"
                  required
                  type="text"
                />
              </b-form-group>
            </b-col>
            <!-- Place Publish -->
            <b-col cols="12">
              <b-form-group>
                <label for="place">{{ $t('events.actions.place-publish') }}</label>
                <v-select
                  id="place"
                  v-model="placePublishOption"
                  :clearable="false"
                  :reduce="(place) => place.code"
                  :options="placePublishOptions"
                  label="place"
                />
              </b-form-group>
            </b-col>
            <!-- Join url -->
            <b-col v-if="placePublishOption===1" cols="12">
              <b-form-group :label="$t('events.actions.link')" label-for="join-link">
                <b-form-input
                  id="join-link"
                  v-model="itemInput.joinURL"
                  type="url"
                  autofocus
                  trim
                  :placeholder="$t('events.actions.link-placeholder')"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-form-group>
            <label
              class="mt-2"
              for="description"
            >
              {{ $t("form-create-item.description") }}:
            </label>
            <quill-editor v-model="itemInput.description" />
          </b-form-group>
        </b-form>
      </b-modal>

      <!-- Apply to job offer modal -->
      <b-modal
        v-model="isContactModalVisible"
        :title="$t('jobs.detail.apply')"
        size="lg"
        hide-footer
      >
        <job-apply-form
          :job="item.key"
          @close-modal="isContactModalVisible = false"
          @new-applicant="showApplicants"
        />
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import JobsPlaceholder from '@/assets/images/placeholders/light/jobs.svg';
import CustomfieldsModal from '@/@core/components/modal/CustomfieldsModal.vue';
import ClassifiersModal from '@/@core/components/modal/ClassifiersModal.vue';
import ShareModal from '@/@core/components/modal/ShareModal.vue';
import { quillEditor } from 'vue-quill-editor';
import { checkPermissions } from '@/@core/utils/roles-utils';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
import { dateValidator } from '@/@core/utils/validations/validators';
import Service from '@/config/service-identifiers';
import UnshareItemModal from '@/@core/components/modal/UnshareItemModal.vue';
import vSelect from 'vue-select';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';
import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
import JobApplyForm from './JobApplyForm.vue';

export default {
  name: 'JobDetails',

  components: {
    vSelect,
    CustomfieldsModal,
    ClassifiersModal,
    ShareModal,
    quillEditor,
    JobApplyForm,
    UnshareItemModal,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      isLoading: true,
      itemInput: {},
      isClassifiersModalVisible: false,
      isCustomFieldsModalVisible: false,
      isShareModalVisible: false,
      imageSrc: null,
      isContactModalVisible: false,
      currencyCode: [{ value: null, text: 'Select a Currency...' }],
      isUnshareModalVisible: false,
      placePublishOption: 0,
      placePublishOptions: [
        { code: 0, place: this.$t('events.event-details.here-place') },
        { code: 1, place: this.$t('events.event-details.external-place') },
      ],
    };
  },
  computed: {

    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    id() {
      return this.$route.params.id;
    },
    item() {
      if (this.$store.getters.jobs) {
        const item = this.$store.getters.jobs.unpaginated[0];
        return item;
      }
      return {};
    },
    jobsPlaceholder() {
      return JobsPlaceholder;
    },

    classifiersData() {
      if (this.$store.getters.classifiers.job) {
        return this.$store.getters.classifiers.job.unpaginated.reduce(
          (acc, current) => {
            const x = acc.findIndex((item) => item.key === current.key);
            if (x === -1) {
              return acc.concat([current]);
            }
            acc[x] = current;
            return acc;
          },
          [],
        );
      }
      return [];
    },
    classifiers() {
      return this.classifiersData;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    mainCollective() {
      return this.$store.getters.mainCollective;
    },
    mainSpace() {
      return this.mainCollective.key ? this.mainCollective : this.currentCollective;
    },
    showApplyButton() {
      return this.mainSpace.slug !== "seqc";
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    isOwner(){
      return this.loggedUser.key === this.item?.ownedByMember?.key
        || this.loggedUser.email === this.item?.ownedByMember?.email
        || this.loggedUser.email === this.item?.ownedByUser?.email;
    },
    canEdit() {
      return (
        this.isOwner
        || checkPermissions(
          'update',
          'jobs',
          this.loggedMemberRoles,
          this.$store.getters.currentCollective,
        )
      );
    },
    canShare() {
      return checkPermissions(
        'share',
        'jobs',
        this.loggedMemberRoles,
        this.$store.getters.currentCollective,
      );
    },
    canDelete() {
      return this.isOwner || checkPermissions(
        'delete',
        'jobs',
        this.loggedMemberRoles,
        this.$store.getters.currentCollective,
      );
    },
    canGetApplicants() {
      return this.isOwner || checkPermissions(
        'get',
        'jobApplicants',
        this.loggedMemberRoles,
        this.$store.getters.currentCollective,
      );
    },
    sortedClassifiersTypes() {
      const newObject = {};
      this.classifiers?.forEach((item) => {
        if (
          !newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName,
            )
          ]
        ) {
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName,
            )
          ] = [item];
        } else {
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName,
            )
          ].push(item);
        }
      });
      return newObject;
    },
    appTitle() {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.app?.customizationName,
      );
    },
    app() {
      return Object.values(this.$store.getters[ENABLED_APPS_GETTERS.enabledApps]).find(({ id }) => id === 20);
    },
  },

  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    await this.fetchData();
    this.itemInput = { ...this.item };
    await this.fetchCurrencyCodes();
    this.showApplicants();
    this.updateBreadcrumbs();
    await this.fetchClassifiers();
  },
  methods: {
    translate(field) {
      return translateTranslationTable(this.$store.state.locale.currentLocale, field);
    },
    openEditModal() {
      this.itemInput = {};
      this.itemInput = { ...this.item };
      this.placePublishOption = this.itemInput.joinURL ? 1 : 0;
    },
    async fetchCurrencyCodes() {
      const response = await this.$store.$service[Service.BackendClient].get(
        'currencies',
        {
          params: {
            communityKey: this.$store.getters.currentCollective.key,
            count: 164,
            forceAPICall: true,
          },
        },
      );
      const currencyArray = response.data.data;
      for (const indexCurrency in currencyArray) {
        const newCurrency = {
          value: currencyArray[indexCurrency].ISO,
          text: `${currencyArray[indexCurrency].ISO} (${currencyArray[indexCurrency].symbol})`,
          ...currencyArray[indexCurrency],
        };
        this.currencyCode.push(newCurrency);
      }
    },
    // TODO: move to utils
    // base64Encode(data) {
    //   return new Promise((resolve, reject) => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(data);
    //     reader.onload = () => resolve(reader.result);
    //     reader.onerror = (error) => reject(error);
    //   });
    // },
    // Call store action (with dispach) to load data from backend
    getImageSrc(url) {
      return getImageResource(url);
    },
    showApplicants() {
      if (this.canGetApplicants) {
        this.fetchDataJobApplicants();
      }
    },
    async deleteItem(item) {
      try {
        await this.$store.dispatch('unshareItem', {
          item: {
            itemType: 'jobs',

            key: this.item.key,
          },
        });
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('unshare.toast-messages.success', { item: 'Job' }),
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        });
        this.$router.push(`/${item.ownedByCommunity.slug}/jobs`);
      } catch (error) {
        console.error(error);
      }
    },
    async handleEditJob() {
      try {
        const requestConfig = {
          key: this.item.key,
          jobKey: this.item.key,
          title: this.itemInput.title,
          responsibilities: this.itemInput.responsibilities,
          vacants: this.itemInput.vacants,
          minBruttoSalary: this.itemInput.minBruttoSalary,
          maxBruttoSalary: this.itemInput.maxBruttoSalary,
          startDate: this.itemInput.startDate,
          endDate: this.itemInput.endDate,
          minYearsExp: this.itemInput.minYearsExp,
          maxYearsExp: this.itemInput.maxYearsExp,
          description: this.itemInput.description,
          currencyCode: this.itemInput.currency,
          deleteJoinURL: this.placePublishOption === 0 ? true : null,
          joinURL: this.placePublishOption === 1 ? this.itemInput.joinURL : null,
          location: {
            name: this.itemInput.location.name,
            locality: this.itemInput.location.locality,
            region: this.itemInput.location.region,
          },
        };
        await this.$store.dispatch('editItem', {
          item: {
            itemType: 'jobs',
            requestConfig,
          },
        });
        this.notifySuccess(this.$t('success-message.general-success-edit'));
        this.itemInput = {};
        this.updateBreadcrumbs();
        this.fetchData();
      } catch {
        this.notifyError(this.$t('error-message.general-error'));
      }
    },
    async fetchClassifiers() {
      await this.$store.dispatch('getItems', {
        storedKey: 'job',
        itemType: 'backoffice/classifiers',
        customName: 'classifiers',
        forceAPICall: true,
        page: 1,
        requestConfig: {
          jobKey: this.item?.key,
        },
      });
      this.isLoading = false;
      this.updateBreadcrumbs();
    },
    translateTranslationTable,
    translatedFieldName(field) {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        field.name,
      );
    },
    classifierStyle(classifier) {
      return {
        color: classifier.fontColor,
        'background-color': classifier.backgroundColor,
      };
    },
    async fetchData() {
      await this.$store.dispatch('getItems', {
        itemType: 'jobs',
        forceAPICall: true,
        requestConfig: {
          jobKey: this.id,
        },
      });
      this.updateBreadcrumbs();
      this.isLoading = false;
    },
    async fetchDataJobApplicants() {
      if (this.$store.getters.jobApplicants.unpaginated.length > 0) {
        return;
      }
      await this.$store.dispatch('getItems', {
        itemType: 'jobApplicants',
        requestConfig: { jobKey: this.item?.key },
      });
      this.isLoading = false;
    },
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text: this.appTitle || 'jobs.breadcrumb-text.job-list',
          to: { name: 'jobs' },
        },
        {
          text: this.item?.title
            ? this.item.title
            : 'jobs.breadcrumb-text.job-details',
          active: true,
        },
      ];
      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    },
    async handleCustomFields() {
      await this.fetchData();
    },
    getValue(value) {
      if (dateValidator(value)) {
        return value.split('-').reverse().join('-');
      }
      if (value === true) {
        return this.$t('yes');
      }
      if (value === false) {
        return this.$t('no');
      }
      return value;
    },
  },
};
</script>
<style lang="scss" scoped>
.product-img {
  max-height: 500px;
}
.share-icon {
  position: absolute;
  top: -48px;
  right: 0;
  cursor: pointer;
}
.edit-icon {
  position: absolute;
  top: -48px;
  right: 60px;
  cursor: pointer;
}
.b-nav-dropdown .dropdown-toggle::after {
  display: none !important;
}
#dropdown-dropleft {
  list-style-type: none !important;
}
</style>
